<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title">处理理由：</p>
          <RadioGroup v-model="reason">
            <Radio label="已核实">
              已核实
            </Radio>
            <Radio label="已处理">
              已处理
            </Radio>
            <Radio label="已上报">
              已上报
            </Radio>
          </RadioGroup>
        </li>
        <li class="form-ul-li">
          <p class="li-title">处理备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '处理'
    },
    visible: Boolean,
    dataId: String,
    type: String
  },
  data () {
    return {
      reason: '已核实',
      remark: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 确定按钮
    onClickConfirm () {
      let params = {
        dealType: this.reason,
        dealRemark: this.remark,
      }
      if (this.type == 'carViolation') {
        params.carViolationIds = this.dataId
        this.$http.dealCarViolations(params).then(res => {
          this.$Message.info(res.message)
          this.$emit('onClickConfirm', params)
          this.$emit('onChange', false)
        })
      } else if (this.type == 'personViolation') {
        params.dlbjViolationIds = this.dataId
        this.$http.dealPersonViolations(params).then(res => {
          this.$Message.info(res.message)
          this.$emit('onClickConfirm', params)
          this.$emit('onChange', false)
        })
      } else if (this.type == 'carAlarm') {
        params.alarmCarIds = this.dataId
        this.$http.dealCarAlarm(params).then(res => {
          this.$Message.info(res.message)
          this.$emit('onClickConfirm', params)
          this.$emit('onChange', false)
        })
      } else if (this.type == 'personAlarm') {
        params.alarmPersonIds = this.dataId
        this.$http.dealPersonAlarm(params).then(res => {
          this.$Message.info(res.message)
          this.$emit('onClickConfirm', params)
          this.$emit('onChange', false)
        })
      } else if (this.type == 'ashbinAlarm') {
        params.alarmAshbinIds = this.dataId
        this.$http.dealAshbinAlarm(params).then(res => {
          this.$Message.info(res.message)
          this.$emit('onClickConfirm', params)
          this.$emit('onChange', false)
        })
      } else if (this.type == 'facilityAlarm') {
        params.alarmSheshiIds = this.dataId
        this.$http.dealFacilityAlarm(params).then(res => {
          this.$Message.info(res.message)
          this.$emit('onClickConfirm', params)
          this.$emit('onChange', false)
        })
      }
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.reason = '已核实'
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>