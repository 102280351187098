<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="780">
    <Table ref="table"
           :height="tableHeight"
           :columns="columns"
           :data="data"
           height="350"
           border
           stripe
           :loading="tableLoading"
           @on-selection-change="onChangeSelect"
           @on-sort-change="onChangeSort">
      <template slot-scope="{ row }"
                slot="operation">
        <Button type="text"
                class="tableOperaBtn"
                @click.stop="onClickDeal(row)">处理</Button>
      </template>
    </Table>
    <!-- <Page class="m-t-10 m-l-10"
          :total="totalPage"
          :current="pageNum"
          :page-size="pageSize"
          show-total
          show-sizer
          show-elevator
          @on-change="onPageChange"
          @on-page-size-change="onPageSizeChange" /> -->
    <!-- <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
      :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div> -->
  </Modal>
</template>

<script>
export default {
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '处理记录'
    },
    visible: Boolean,
    dataId: String,
    type: String
  },
  data () {
    return {
      loading: false,
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      data: [],
      columns: [
        {
          title: '处理人',
          key: 'dealUName',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '处理时间',
          key: 'createTime',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '处理方式',
          key: 'dealType',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.dealType ? row.dealType : '待处理');
          }
        },
        {
          title: '备注',
          key: 'dealRemark',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
      ]
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      if (this.type == 'alarm') {
        this.$http.getProcessingAlarmRecord({
          alarmId: this.dataId
        }).then(res => {
          this.data = res.result
        })
      } else {

        this.$http.getProcessingViolationRecord({
          violationId: this.dataId
        }).then(res => {
          this.data = res.result
        })
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getDetail()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getDetail()
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入计划名称');
      // let params = {
      //   plateNumber: this.plateNumber,
      //   institutionTreeId: this.institutionTreeId,
      // }
      // if (this.dataId) params.id = this.dataId
      // this.$store.dispatch('createOrEditCar', params).then(res => {
      //   this.$emit('onClickConfirm', params)
      //   this.$emit('onChange', false)
      // })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.data = []
    }
  }
}
</script>

<style lang="scss" scoped>
</style>